import { useCallback, useRef, forwardRef } from 'react'
import { Button, Grid, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { State, OnChange } from './Component'

interface Props {
    state: State;
    onChange: OnChange;
}

const MyTextField = styled((props: OutlinedTextFieldProps) => {
    return (
        <TextField {...props}/>
    )
})(() => {
    return {
        marginBottom: 10
    }  
})

const RefTextField = forwardRef((props: OutlinedTextFieldProps, ref) => {
    return <MyTextField inputRef={ref}  {...props}/>
})

const FileBox = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        gap: 5
    }  
})

function SendComponent(props: Props) {
    const { state, onChange } = props

    const refFile = useRef<OutlinedTextFieldProps[]>([])

    const handleChange = useCallback((e) => {
        const { name, value } = e.target

        onChange(name, value)
    }, [onChange])

    const handleChangeChecked = useCallback((e) => {
        const { name, checked } = e.target

        onChange(name, checked)
    }, [onChange])

    const handleChangeFile = useCallback((e) => {
        const { name, files } = e.target
        
        onChange(name, files[0])
    }, [onChange])



    function handleRemoveFile(i: number) {
        const state = `file${(i+1)}`
        
        refFile.current[i].value = ''

        onChange(state, null)
    }

    return (
        <Grid container spacing={1}>
            <Grid item sm={2} md={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">기본 메일폼 언어</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.lang}
                        label="기본 메일폼 언어"
                        name="lang"
                        onChange={handleChange}
                        sx={{width: 150}}
                    >
                        <MenuItem value="ko">한글</MenuItem>
                        <MenuItem value="en">영어</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={10} md={10}>
                <FormControlLabel control={<Checkbox checked={state.introduce} />} name="introduce" label="소개서" onChange={handleChangeChecked}/>
                <FormControlLabel control={<Checkbox checked={state.bankbook} />} name="bankbook" label="통장사본" onChange={handleChangeChecked}/>
                <FormControlLabel control={<Checkbox checked={state.register} />} name="register" label="사업자등록증" onChange={handleChangeChecked}/>
                <FormControlLabel control={<Checkbox checked={state.seal} />} name="seal" label="인감" onChange={handleChangeChecked}/>
                <FormControlLabel control={<Checkbox checked={state.isHtml} />} name="isHtml" label="HTML" onChange={handleChangeChecked}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{marginTop: 10}}>
                <MyTextField variant="outlined" name="from" value={state.from} label="보내는 주소 아이디" fullWidth onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <MyTextField variant="outlined" name="to" value={state.to} label="메일 주소" fullWidth onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <MyTextField variant="outlined" name="subject" value={state.subject} label="제목" fullWidth onChange={handleChange}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <MyTextField variant="outlined" name="body" value={state.body} label="내용" multiline minRows={5} fullWidth onChange={handleChange}/>
            </Grid>
            <Grid item>
                <FileBox>
                    <RefTextField variant="outlined" name="file1" ref={ref => refFile.current[0]= ref} type="file" onChange={handleChangeFile}/>
                    {
                        state.file1 && <Button color="secondary"  onClick={() => handleRemoveFile(0)}>삭제</Button>
                    }
                </FileBox>
            </Grid>
            <Grid item>
                <FileBox>
                    <RefTextField variant="outlined" name="file2" ref={ref => refFile.current[1]= ref} type="file" onChange={handleChangeFile} />
                    {
                        state.file2 && <Button color="secondary"  onClick={() => handleRemoveFile(1)}>삭제</Button>
                    }
                </FileBox>
            </Grid>
            <Grid item>
                <FileBox>
                    <RefTextField variant="outlined" name="file3" ref={ref => refFile.current[2]= ref} type="file" onChange={handleChangeFile} />
                    {
                        state.file3 && <Button color="secondary"  onClick={() => handleRemoveFile(2)}>삭제</Button>
                    }
                </FileBox>
            </Grid>
        </Grid>
    )
}

export default SendComponent
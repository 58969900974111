export const SHOW = 'snackbar/SHOW'
export const HIDE = 'snackbar/HIDE'

const initState = {
    show: false,
    message: ''
}

interface Props {
    type: string;
    message: string;
}

function Snackbar(state = initState, action: Props) {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                message: action.message
            }
        case HIDE:
            return {
                show: false,
                message: ''
            }
        default:
            return state
    }
}

export default Snackbar
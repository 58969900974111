import gql from 'graphql-tag'
import { CronProps } from './table_schema'

export const LOGIN = gql`
    mutation AdminLogin($id: String!, $pwd: String!) {
        adminLogin(id: $id, pwd: $pwd) 
    }
`

export const LOGOUT = gql`
    mutation AdminLogout {
        adminLogout 
    }
`

export const INSIGHT = gql`
    query Insight {
        insight {
            todayJoinCount
            totalJoinCount
            todayJoinPayLevel1000Count
            todayJoinPayLevel10Count
            todayJoinPayLevel100Count
            totalJoinPayLevel10Count
            totalJoinPayLevel100Count
            todayPayLevel1000OneCount
            todayPayLevel1000TwelveCount
            todayPayLevel10OneCount
            todayPayLevel10TwelveCount
            todayPayLevel100OneCount
            todayPayLevel100TwelveCount
            totalPayLevel1000OneCount
            totalPayLevel1000TwelveCount
            totalPayLevel10OneCount
            totalPayLevel10TwelveCount
            totalPayLevel100OneCount
            totalPayLevel100TwelveCount
            todayResponseCount
            monthResponseCount
            lists {
                interval
                joinCount
                joinPayLevel10Count
                joinPayLevel100Count
                payLevel1000OneCount
                payLevel1000TwelveCount
                payLevel10OneCount
                payLevel10TwelveCount
                payLevel100OneCount
                payLevel100TwelveCount
                resposeCount
            }
        } 
    }
`






export interface InsightProps {
    todayJoinCount: number
    totalJoinCount: number
    todayJoinPayLevel1000Count: number
    todayJoinPayLevel10Count: number
    todayJoinPayLevel100Count: number
    totalJoinPayLevel10Count: number
    totalJoinPayLevel100Count: number
    todayPayLevel1000OneCount: number
    todayPayLevel1000TwelveCount:number
    todayPayLevel10OneCount: number
    todayPayLevel10TwelveCount: number
    todayPayLevel100OneCount: number
    todayPayLevel100TwelveCount: number
    totalPayLevel1000OneCount: number
    totalPayLevel1000TwelveCount: number
    totalPayLevel10OneCount: number
    totalPayLevel10TwelveCount: number
    totalPayLevel100OneCount: number
    totalPayLevel100TwelveCount: number
    todayResponseCount: number
    monthResponseCount: number
    lists: [{
        interval: number
        joinCount: number
        joinPayLevel1000Count: number
        joinPayLevel10Count: number
        joinPayLevel100Count: number
        payLevel1000OneCount: number
        payLevel1000TwelveCount: number
        payLevel10OneCount: number
        payLevel10TwelveCount: number
        payLevel100OneCount: number
        payLevel100TwelveCount: number
        resposeCount: number
    }]
}

// cron 값 가져오기
export const GET_CRON = gql`
    query Cron {
        cron {
            googlesheet
            slackpush
            webhook
            webhook_resend
        } 
    }
`

// 크론 상태값 업데이트
export const UPDATE_CRON = gql`
    mutation UpdateCron($data: JSONObject!) {
        updateCron(data: $data) 
    }
`

// 웹훅 리미트 값 가져오기
export const GET_WEBHOOK_LIMIT = gql`
    query WebhookLimit {
        webhookLimit {
            webhook
            webhook_resend
            send_nos {
                survey_no
                count
            }
            resend_nos {
                survey_no
                count
            }
        } 
    }
`

// 크론 상태값 업데이트
export const UPDATE_WEBHOOK_LIMIT  = gql`
    mutation UpdateWebhookLimit($webhook: Int!, $webhook_resend: Int!) {
        updateWebhookLimit(webhook: $webhook, webhook_resend: $webhook_resend) 
    }
`

// 슬랙 구글시트 설문번호별로 제어
export const GET_SURVEY_APPLICATION_CONTROL = gql`
    query SurveyApplicationControl {
        surveyApplicationControl {
            googlesheet
            slackpush
            webhook
            webhook_resend
        } 
    }
`

export const UPDATE_SURVEY_APPLICATION_CONTROL = gql`
    mutation UpdateSurveyApplicationControl($data: JSONObject!) {
        updateSurveyApplicationControl(data: $data) 
    }
`
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { RootState } from 'reducer'
import { HIDE } from 'reducer/snackbar'

function SimpleSnackbar() {
	const { show, message } = useSelector((state:RootState) => state.snackbar)

	const dispatch = useDispatch()

	const handleClose = () => {
		dispatch({ type: HIDE })
	}

	const action = (
		<>
    	<Button color="secondary" size="small" onClick={handleClose}>UNDO</Button>
    		<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        		<CloseIcon fontSize="small" />
      		</IconButton>
    	</>
  	)

  	return (
		<Snackbar open={show} autoHideDuration={6000} onClose={handleClose} message={message} action={action}/>
  	)
}


export default SimpleSnackbar
import { combineReducers } from 'redux'
import backdrop from './backdrop'
import snackbar from './snackbar'
import mail from './mail'
import taxtListChecked from './tax/list/checked'

const rootReducer = combineReducers({
    backdrop, 
    snackbar,
    mail,
    taxtListChecked
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default () => {
    const backdrop = useSelector((state:RootState) => state.backdrop)

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000000 }} open={backdrop.show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

import { useState, useCallback, Fragment } from 'react';
import oc from 'open-color'
import moment from 'moment'
import { numToKorean } from 'num-to-korean'
import { Container, TextField, Grid, Divider } from "@mui/material"
import PayPaperItemComponent from './PayPayperItem'
import PreviewComponent from './Preview'

export interface List {
    id: number;
    name: string;
    month: number;
    unitPrice: number;
    purePrice: number;
    vatPrice: number;
    etc: string;
}

export interface State {
    y: string,
    m: string,
    d: string,
    customer: string,
    number: string,
    company: string,
    vip: string,
    uptae: string,
    jongmok: string,
    tel: string,
    totalKoreanPrice:  string,
    totalPrice: number
}

export type OnChangeList = (args: List) => void;


const listDummy:List[] = [
    {
        id: 0,
        name: '',
        month: 0,
        unitPrice: 0,
        purePrice: 0,
        vatPrice: 0,
        etc: ''
    },
    {
        id: 1,
        name: '',
        month: 0,
        unitPrice: 0,
        purePrice: 0,
        vatPrice: 0,
        etc: ''
    },
    {
        id: 2,
        name: '',
        month: 0,
        unitPrice: 0,
        purePrice: 0,
        vatPrice: 0,
        etc: ''
    },
    {
        id: 3,
        name: '',
        month: 0,
        unitPrice: 0,
        purePrice: 0,
        vatPrice: 0,
        etc: ''
    }
]

function PayPerComponent() {
    const [ y, m , d ] = moment().format('YYYY-MM-DD').split('-')

    const [ state, setState ] = useState({
        y,
        m,
        d,
        customer: '',
        number: '587-06-02337',
        company: '리스토다움',
        vip: '윤지은',
        uptae: '정보통신업',
        jongmok: '응용 소프트웨어 개발 및 공급업',
        tel: '010-4862-3144',
        totalKoreanPrice: numToKorean(0),
        totalPrice: 0
    })

    const [ list, setList ] = useState(listDummy)

    function handleChangeState(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target

        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleChangeList = useCallback((args: List) => {

        let totalPrice = 0
        setList(prevState => {
            return prevState.map((c: List) => {
                const val = args.id === c.id ? args : c
                totalPrice += (Number(val.purePrice) + Number(val.vatPrice))

                return val  
            })
        })

        setState(prevState => ({
            ...prevState,
            totalPrice,
            totalKoreanPrice: numToKorean(totalPrice)
        }))
    }, [])


    return (
        <Container>
            <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <b>견적일자</b><br/>
                    <TextField variant="standard" label="년" name="y" value={state.y} sx={{width: 50}} onChange={handleChangeState}/>
                    <TextField variant="standard" label="월" name="m" value={state.m} sx={{width: 30}} onChange={handleChangeState}/>
                    <TextField variant="standard" label="일" name="d" value={state.d} sx={{width: 30}} onChange={handleChangeState}/>
                    <br />
                    <br />
                    <TextField variant="standard" label="고객명" name="customer" value={state.customer} sx={{width: 100}} onChange={handleChangeState}/>
                   
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="사업자번호" name="number" value={state.number} fullWidth onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="상호" name="company" value={state.company} fullWidth onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="대표" name="vip" value={state.vip} onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="업태" fullWidth name="uptae" value={state.uptae} onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="종목" fullWidth multiline name="jongmok" value={state.jongmok} onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField variant="standard" label="전화번호" fullWidth name="tel" value={state.tel} onChange={handleChangeState}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                {
                    list.map((c, i) => (
                        <Fragment key={`key-${c.id}`}>
                        <PayPaperItemComponent {...c} onChange={handleChangeList}/>
                        <Divider sx={{marginTop: 5, marginBottom: 3, background: oc.pink[9], height: 3}}/>
                        </Fragment>
                    ))
                }
                </Grid>
            </Grid>
            </div>
           <PreviewComponent state={state} list={list}/>
        </Container>
    )
}

export default PayPerComponent
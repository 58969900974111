export type Kind = 0 | 1 | 2 | 3 | 4

export enum Levels {
    Level1000="level1000",
    Level100="level100",
    Level10="level10",
    Level0="level0"
}

export type Month = 12 | 1

export enum PayMethod {
    Card='card',   // 앱카드
    Trans='trans', // 계좌이체
    Vbank='vbank', // 가상계좌
    Epay='epay',  // 간편결제
    Acard='acard', // 카드
    Nbank='nbank' // 무통장
}

export enum PayResult {
    Apply='apply',   // 승인
    Non_Apply='non_apply', // 미승인
    Cancel='cancel' // 취소
}

export enum UserFiredKind {
    Used='used',   // 자주 사용하지 않음
    Service='service', // 서비스 이용 불편
    Price='price', // 가격 불만족
    Respond='respond', // 고객 서비스 응대 불만
    Etc='etc' // 직접 입력
}

export enum LimitFileSize {
    A='A',
    B='B',
    C='C',
    D='D',
    E='E'
}

export enum TaxType {
    Personal='personal',
    Company='company'
}

export const LevelsName = {
    level0: '무료',
    level10: '스탠다드',
    level100: '프리미엄',
    level1000: '베이직',
} 

export const LevelsPrice:{[ab: string]: number  } = {
    level0: 0,
    level10: 39000,
    level100: 59000,
    level1000: 19000,
}

export const MonthName:{[ab: string]: string  } = {
    1: '월간',
    12: '연간'
} 

export const PayMethodName:{[ab: string]: string  } = {
    card: '카드',
    trans: '계좌이체',
    vbank: '가상계좌',
    epay: '간편결제',
    acard: '카드',
    nbank: '무통장'
} 

export const PayResultName:{[ab: string]: string  } = {
    apply: '승인',
    non_apply: '미승인',
    cancel: '취소'
} 


export const StatusName:{[ab: number]: string} = {
    10: '정상',
    20: '해지',
    30: '비정상',
    40: '로그인비정상'
}

export const UserFiredKindName:{[ab: string]: string} = {
    used: '자주 사용하지 않음',
    service: '서비스 이용 불편',
    price: '가격 불만족',
    respond: '고객 서비스 응대 불만',
    etc: '기타'
}

export const TaxTypeName:{[ab: string]: string} = {
    personal: '개인 영수증',
    company: '회사 영수증'
}

// 이노페이 통합
export const getTaxTypeName = (isInnopay: number, type: TaxType) => {
    if (isInnopay === 1) return 'innopay-개인'
    if (isInnopay === 2) return 'innopay-회사'
    if (type === 'personal') return 'listovey-개인'
    if (type === 'company') return 'listovey-회사'
    return ''
}
import { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import OuterComponent from 'component/Outer/Component'
import BackdropComponent from 'ccomponent/backdrop'
import SnackbarComponent from 'ccomponent/snackbar'
import MailComponent from 'ccomponent/Mail/Component'

const IndexComponent = lazy(() => import('component/Index/Component'))
const LoginComponent = lazy(() => import('component/Login/Component'))
const UserInfoComponent = lazy(() => import('component/User/Info/Component'))
const UserListComponent = lazy(() => import('component/User/List/Component'))
const UserLoginListComponent = lazy(() => import('component/User/Login/List'))
const PaymentListComponent = lazy(() => import('component/Payment/List/Component'))
const PaymentAnalysisComponent = lazy(() => import('component/PaymentAnalysis/Component'))
const TaxListComponent = lazy(() => import('component/Tax/List/Component'))
const UtilityComponent = lazy(() => import('component/Utility/Component'))
const AlarmComponent = lazy(() => import('component/Alarm/Component'))

const requireLogin = (to:any, from:any, next:any) => {
  	if (to.meta.auth) {
    	if (sessionStorage.getItem('vip.authorization')) {
      		next()
    	}
    	
		next.redirect('/login')
  	} else {
    	next()
  	}
}

const suspenseMsg = '...'

function Loading() {
	return <div>Loading....</div>
}

function App() {
	return (
		<>
		<BrowserRouter>
			<GuardProvider guards={[requireLogin]} loading={Loading} error={Loading}>
			<Switch>
			<GuardedRoute 
				path="/" 
				meta={{auth: true}} 
				exact 
				component={(props:any) => (
					<OuterComponent title="Insight">
						<Suspense fallback={suspenseMsg}>
							<IndexComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/user/info" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="UserInfo">
						<Suspense fallback={suspenseMsg}>
							<UserInfoComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/user/list" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="User List">
						<Suspense fallback={suspenseMsg}>
							<UserListComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/user/login/list" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="User Login List">
						<Suspense fallback={suspenseMsg}>
							<UserLoginListComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/payment/list" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="Payment List">
						<Suspense fallback={suspenseMsg}>
							<PaymentListComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/tax/list" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="Tax List">
						<Suspense fallback={suspenseMsg}>
							<TaxListComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/paymentAnalysis" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="Payment Analysis">
						<Suspense fallback={suspenseMsg}>
							<PaymentAnalysisComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>


			<GuardedRoute 
				path="/utility" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="Utility">
						<Suspense fallback={suspenseMsg}>
							<UtilityComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute 
				path="/alarm" 
				meta={{auth: true}}
				exact 
				component={(props:any) => (
					<OuterComponent title="Alarm">
						<Suspense fallback={suspenseMsg}>
							<AlarmComponent {...props}/>
						</Suspense>
					</OuterComponent>
				)}
			/>

			<GuardedRoute path="/login" exact component={(props:any) => <Suspense fallback={suspenseMsg}><LoginComponent {...props}/></Suspense>} />
			</Switch>
			</GuardProvider>
		</BrowserRouter>
		<BackdropComponent/>
		<SnackbarComponent/>
		<MailComponent/>
		</>
	)
}

export default App

import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import InsightsIcon from '@mui/icons-material/Insights'
import PeopleIcon from '@mui/icons-material/People'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import ReceiptIcon from '@mui/icons-material/Receipt'
import LogoutIcon from '@mui/icons-material/Logout'
import PaymentIcon from '@mui/icons-material/Payment'
import ConstructionIcon from '@mui/icons-material/Construction'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import { LOGOUT } from 'gql/admin_info'

const drawerWidth = 240

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window | undefined;
  title: string;
  children: JSX.Element | JSX.Element[];
}

export default function ResponsiveDrawer(props: Props) {
    const { window, title, children } = props
    const [mobileOpen, setMobileOpen] = useState(false)

    const history = useHistory()

    const location = useLocation()

    const [ logout ] = useMutation(LOGOUT, {
        onCompleted: () => {
            sessionStorage.setItem('vip.authorization', '')
            history.push('/login')
        }
    })

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    function handlePush(loc: string) {
        history.push(loc)
    }

    const drawer = (
        <div>
        <Toolbar />
        <Divider />
        <List>
            <ListItem button selected={location.pathname === '/'}  onClick={() => handlePush('/')}>
                <ListItemIcon>
                    <InsightsIcon/>
                </ListItemIcon>
                <ListItemText primary="Insight"/>
            </ListItem>
            <Divider/>
            <ListItem button selected={location.pathname === '/user/info'} onClick={() => handlePush('/user/info')}>
                <ListItemIcon>
                    <AssignmentIndIcon/>
                </ListItemIcon>
                <ListItemText primary="User Info"/>
            </ListItem>
            <ListItem button selected={location.pathname === '/user/list'} onClick={() => handlePush('/user/list')}>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary="User List"/>
            </ListItem>
            <ListItem button selected={location.pathname === '/user/login/list'} onClick={() => handlePush('/user/login/list')}>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary="User Login List"/>
            </ListItem>
            <Divider/>
            <ListItem button selected={location.pathname === '/payment/list'} onClick={() => handlePush('/payment/list')}>
                <ListItemIcon>
                    <PaymentIcon/>
                </ListItemIcon>
                <ListItemText primary="Payment List"/>
            </ListItem>
            <ListItem button selected={location.pathname === '/tax/list'} onClick={() => handlePush('/tax/list')}>
                <ListItemIcon>
                    <ReceiptIcon/>
                </ListItemIcon>
                <ListItemText primary="Tax List"/>
            </ListItem>
            <ListItem button selected={location.pathname === '/paymentAnalysis'} onClick={() => handlePush('/paymentAnalysis')}>
                <ListItemIcon>
                    <ReceiptIcon/>
                </ListItemIcon>
                <ListItemText primary="Payment Analysis"/>
            </ListItem>
            <Divider/>
            <ListItem button selected={location.pathname === '/utility'} onClick={() => handlePush('/utility')}>
                <ListItemIcon>
                    <ConstructionIcon/>
                </ListItemIcon>
                <ListItemText primary="Utility"/>
            </ListItem>
            <ListItem button selected={location.pathname === '/alarm'} onClick={() => handlePush('/alarm')}>
                <ListItemIcon>
                    <AccessAlarmIcon/>
                </ListItemIcon>
                <ListItemText primary="Alarm"/>
            </ListItem>
            <Divider/>
        </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
            }}
        >
        <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', '& > div:nth-of-type(1)': { display: 'flex', alignItems: 'center' } }}>
            <div>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                {title}
                </Typography>
            </div>
            <IconButton sx={{color: 'white'}} onClick={() => logout()}>
                <LogoutIcon/>
            </IconButton>
        </Toolbar>
        </AppBar>
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            aria-label="mailbox folders"
        >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
        >
            {drawer}
        </Drawer>
        </Box>
     
           
            <Container maxWidth="xl" sx={{padding: 3}}>
            <Toolbar />
                {children}
            </Container>
            
      
        </Box>
    )
}
import { StrictMode } from 'react'
import { createStore } from 'redux'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import dotenv from 'dotenv'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'
import rootReducer from './reducer'
import fragment from './fragment.json'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

dotenv.config({path: `./.env.${process.env.NODE_ENV}`})

const store = process.env.NODE_ENV === 'development' ? createStore(rootReducer) : createStore(rootReducer)

const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_API}/graphql`
})

const wsLink = () => {
	return 1
}

/*
const wsLink = new WebSocketLink({
	uri: process.env.REACT_APP_WEB_SOCKET || '',
	options: {
		reconnect: true
	}
})
*/

/* 
split 함수는 세 가지 매개 변수를 사용
* 실행할 각 작업에 대해 호출되는 함수
* 함수가 "truthy"값을 반환하는 경우 작업에 사용할 링크
* 함수가 "거짓"값을 반환하는 경우 작업에 사용할 링크
*/
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
	},
	wsLink,
	uploadLink
)

const authLink = new ApolloLink(( operation:any, forward:any ) => {

	const authorization = sessionStorage.getItem('vip.authorization')

	operation.setContext({
		headers: {
			'vip.authorization': authorization,
			lang: 'ko',
			timezone: '+09:00'
		}
	})

	return forward(operation)
})

  
const cache = new InMemoryCache({
	possibleTypes: fragment
})

const client = new ApolloClient({
	link: authLink.concat(splitLink),
	cache
})



ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<StrictMode>
				<App />
			</StrictMode>
		</Provider>
  	</ApolloProvider>,
  	document.getElementById('root')
)

reportWebVitals()
export const CHANGE = 'tax/list/checked/CHANGE'
export const INIT = 'tax/list/checked/INIT'

const initState:number[] = []

interface Props {
    type: string;
    number: number;
    checked: boolean;
}

function TaxListChecked(state = initState, action: Props) {
    switch(action.type) {
        case CHANGE:
            return !action.checked ? state.filter(number => number !== action.number) : [ ...state, action.number ]
        case INIT:
            return initState
        default:
            return state
    }
}

export default TaxListChecked


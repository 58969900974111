import { createRef } from 'react'
import ReactToPdf from 'react-to-pdf'
import HtmlParser from 'react-html-parser'
import moment from 'moment'
import oc from 'open-color'
import { Button, Typography, Box, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { State, List } from './PayPaper'
import { numberFormat } from '../../utils/index';

interface Props {
    state: State;
    list: List[];
}


const MyContainer = styled((props) => {
    return (
        <Container {...props}/>
    )
})(() => {
    return {
        overflow: 'scroll',
        '& > div': {
            display: 'table',
            width: 740,
            height: 841
        }
    }  
})

const MyBox = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: 740,
        border: `2px solid ${oc.gray[9]}`,
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
        },
        '& > div > div:nth-of-type(1)': {
            verticalAlign: 'middle',
            padding: 10
        
        },
        '& > div > div:nth-of-type(2)': {
            width: 40,
            textAlign: 'center',
            verticalAlign: 'middle',
            background: oc.gray[5],
            borderLeft: `1px solid ${oc.gray[6]}`,
            borderRight: `1px solid ${oc.gray[6]}`
        },        
        '& > div > div:nth-of-type(3)': {
            width: 450
        },
    }  
})

const MyBox2 = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: 740,
        border: `2px solid ${oc.gray[9]}`,
        borderTop: 'none',
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
        },
        '& > div > div:nth-of-type(1)': {
            verticalAlign: 'middle',
            padding: 10,
            background: oc.gray[5],
            borderRight: `1px solid ${oc.gray[6]}`
        
        },       
        '& > div > div:nth-of-type(2)': {
            verticalAlign: 'middle',
            width: 350,
            paddingLeft: 7
        },
    }  
})


const MyTable = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: '100%',
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
            verticalAlign: 'middle',
            height: 20,
            padding: 5,
            border: `1px solid ${oc.gray[6]}`,
            borderTop: 'none'
        },
        '& > div > div:nth-of-type(1)': {
            width: '100px',
            borderLeft: 'none',
            background: oc.gray[3],
            textAlign: 'center'
        
        },
        '& > div > div:nth-of-type(2)': {
            width: 'calc(100% - 100px)',
            borderLeft: 'none',
            borderRight: 'none'
        }
    }  
})

const MyTable2 = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: '100%',
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
            verticalAlign: 'middle',
            height: 20,
            padding: 5,
            border: `1px solid ${oc.gray[6]}`,
            borderTop: 'none'
        },
        '& > div > div:nth-of-type(1)': {
            width: '100px',
            borderLeft: 'none',
            background: oc.gray[3],
            textAlign: 'center'
        
        },
        '& > div > div:nth-of-type(2)': {
            width: '100px',
            borderLeft: 'none',
            borderRight: 'none'
        },
        '& > div > div:nth-of-type(3)': {
            width: '100px',
            borderLeft: 'none',
            background: oc.gray[3],
            textAlign: 'center'
        
        },
        '& > div > div:nth-of-type(4)': {
            width: 'auto',
            borderLeft: 'none',
            borderRight: 'none'
        }
    }  
})


const MyTable3 = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: '100%',
        border: `2px solid ${oc.gray[9]}`,
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
            verticalAlign: 'middle',
            height: 50,
            padding: 5,
            border: `1px solid ${oc.gray[6]}`,
            borderTop: 'none',
            borderRight: 'none',
            textAlign: 'center'
        },
        '& > div > div:first-of-type': {
            borderLeft: 'none'
        },
        '& > div:first-of-type > div': {
            background: oc.gray[3],
            fontWeight: 700
        },
        '& > div > div:nth-of-type(1)': {
            width: '*'
        },
        '& > div > div:nth-of-type(2)': {
            width: 116
        },
        '& > div > div:nth-of-type(3)': {
            width: 116
        },
        '& > div > div:nth-of-type(4)': {
            width: 116
        },
        '& > div > div:nth-of-type(5)': {
            width: 116
        },
        '& > div > div:nth-of-type(6)': {
            width: 136
        }
    }  
})


const MyTable4 = styled((props) => {
    return (
        <Box {...props}/>
    )
})(() => {
    return {
        display: 'table',
        width: '100%',
        border: `2px solid ${oc.gray[9]}`,
        borderTop: 'none',
        '& > div': {
            display: 'table-row',
        },
        '& > div > div': {
            display: 'table-cell',
            verticalAlign: 'middle',
            height: 50,
            padding: 5,
            border: `1px solid ${oc.gray[6]}`,
            borderTop: 'none',
            borderRight: 'none',
            textAlign: 'center'
        },
        '& > div > div:first-of-type': {
            borderLeft: 'none'
        },
        '& > div:first-of-type > div': {
            background: oc.gray[3],
            fontWeight: 700
        },
        '& > div > div:nth-of-type(1)': {
            width: '*'
        },
        '& > div > div:nth-of-type(2)': {
            width: 116
        },
        '& > div > div:nth-of-type(3)': {
            width: 116
        },
        '& > div > div:nth-of-type(4)': {
            width: 136
        }
    }  
})

function PreviewComponent(props: Props) {
    const { state, list } = props
    const ref = createRef()
    const options = {}

    let totalPurePrice = 0
    let totalVatPrice = 0

    for (let i=0; i<list.length; i++) {
        totalPurePrice += Number(list[i].purePrice)
        totalVatPrice += Number(list[i].vatPrice)
    }

    return (
        <div>
        <ReactToPdf targetRef={ref} filename={`견적서-${moment().format('MMDD_HHmmss')}.pdf`} options={options} x={1} y={10}>
            {({toPdf}) => (
               <Button variant="contained" sx={{marginTop: 1}} onClick={toPdf}>PDF로 만들기</Button>
            )}
        </ReactToPdf>
        <div ref={ref} style={{width: 800}}>
        <MyContainer>
            <div>
            <Typography variant="h4" sx={{textAlign: 'center', marginBottom: 5}}>견적서</Typography>
            <MyBox>
                <div>
                    <div>
                        <b>견적일자</b><br/>
                        {state.y}년 {state.m}월 {state.d}일
                        <br />
                        <br />
                        {state.customer} 귀하
                        <br /><br /><br />
                        <Typography sx={{fontWeight: 700}}>아래와 같이 견적합니다.</Typography>  
                    </div>
                    <div>
                        공<br />급<br />자
                    </div>
                    <div>
                        <MyTable>
                            <div>
                                <div>사업자번호</div>
                                <div>{state.number}</div>
                            </div>
                            <div>
                                <div>상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호</div>
                                <div>{state.company}</div>
                            </div>
                            <div>
                                <div>대&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;표</div>
                                <div>
                                    <div style={{position: 'relative'}}>
                                        <div style={{display: 'flex'}}>
                                            <div style={{width: 200}}>
                                                {state.vip}
                                            </div> 
                                            <div>(인)</div>
                                        </div>

                                        <img 
                                            src="./sign.png"
                                            alt="" 
                                            style={{position: 'absolute', top: -17, right: 130, width: 50}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MyTable>
                        <MyTable2>
                            <div>
                                <div>업&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;태</div>
                                <div>{state.uptae}</div>
                                <div style={{borderLeft: `1px solid ${oc.gray[6]}`}}>종&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</div>
                                <div>{state.jongmok}</div>
                            </div>
                        </MyTable2>
                        <MyTable>
                            <div>
                                <div style={{borderBottom: 'none'}}>전 화 번 호</div>
                                <div style={{borderBottom: 'none'}}>{state.tel}</div>
                            </div>
                        </MyTable>
                    </div>
                </div>
                
            </MyBox>
            <MyBox2>
                <div>
                    <div>합계금액(공급가액 + 부가세)</div>
                    <div>금 {state.totalKoreanPrice} 원정 (₩ {numberFormat(state.totalPrice)})</div>
                </div>
            </MyBox2>
            <Box sx={{padding: 2, textAlign: 'center', border: `2px solid ${oc.gray[7]}`, borderTop: 'none', borderBottom: 'none'}}>
                <Typography variant="h6">아래와 같이 견적합니다.</Typography>
            </Box>
            <MyTable3>
                <div>
                    <div>상 품 명</div>
                    <div>개 월</div>
                    <div>단 가(원)</div>
                    <div>공 급 가 액(원)</div>
                    <div>세 액(원)</div>
                    <div>비 고</div>
                </div>
                {
                    list.map(c => (
                        <div key={c.id}>
                            <div>{c.name}</div>
                            <div>{c.month ? `${c.month} 개월` : ''}</div>
                            <div>{c.unitPrice === 0 ? '' : numberFormat(c.unitPrice)}</div>
                            <div>{c.purePrice === 0 ? '' : numberFormat(c.purePrice)}</div>
                            <div>{c.vatPrice === 0  ? '' : numberFormat(c.vatPrice)}</div>
                            <div>{HtmlParser(c.etc.replace(/\n/g, '<br />'))}</div>
                        </div>
                    ))
                }
                
            </MyTable3>
            <MyTable4>
                <div>
                    <div>합&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;계</div>
                    <div>{numberFormat(totalPurePrice)}</div>
                    <div>{numberFormat(totalVatPrice)}</div>
                    <div>{numberFormat(totalPurePrice + totalVatPrice)}</div>
                </div>
            </MyTable4>
            </div>
        </MyContainer>
        </div>
    </div>
    )
}





export default PreviewComponent
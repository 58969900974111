import moment from 'moment'

// number format
export const numberFormat = (x:number | string) => {
    if (!x) return 0

    const customX = String(x).replace(/,/g, '')
    
	return customX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function parseDate(date: string | null, format=0) {
    const _format = format === 0 ? 'YYYY.MM.DD HH:mm:ss' : 'YYYY.MM.DD'

    if (!date) return ''
    return moment(Number(date)).format(_format)
}

// 쿠키
export const cookies = {
    set: (props: { value:string, expires?: number, maxAge?: number, domain: string | undefined}) => {
        const { value, expires, maxAge, domain=`.${process.env.REACT_APP_DOMAIN}` } = props
        let cookie = `${value};path=/;domain=${domain};`
        if (maxAge) cookie = `${cookie}max-age=${maxAge};`
        if (expires) cookie = `${cookie}expires=${expires};`
    
        document.cookie = cookie
    },
    get: (name: string):string => {
        if (!name) return ''

        let x:string 
        let y:string

        const val = document.cookie.split(';');

        for (let i = 0; i < val.length; i+=1) {
            x = val[i].substr(0, val[i].indexOf('='))
            y = val[i].substr(val[i].indexOf('=') + 1)
            x = x.replace(/^\s+|\s+$/g, '')
            if (x === name) {
                return unescape(y)
            }
        }   

        return ''
    },
    remove: (props: { name: string, domain: string }) => {
        const { name, domain } = props
        const expireDate = new Date()

        // 어제 날짜를 쿠키 소멸 날짜로 설정한다.
        expireDate.setDate( expireDate.getDate() - 1 )

        document.cookie = `${name}=; expires=${expireDate.toGMTString()}; path=/; domain=${domain}`
    }
}
export const SHOW = 'mail/SHOW'
export const HIDE = 'mail/HIDE'

const initState = {
    show: false,
    to: ''
}

interface Props {
    type: string
    to: string
}

function Mail(state = initState, action: Props) {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
                to: action.to || initState.to
            }
        case HIDE:
            return {
                show: false,
                to: initState.to
            }
        default:
            return state
    }
}

export default Mail


import { useState, memo, useEffect } from 'react'
import { numberFormat } from 'utils'
import { ButtonGroup, Button, Grid } from "@mui/material"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { LevelsName, LevelsPrice, MonthName, Levels } from 'policy'
import { styled } from '@mui/material/styles'
import { List, OnChangeList } from './PayPaper'

interface Props extends List {
    onChange: OnChangeList;
}

enum GetValueVariable {
    Level1000One,
    Level1000Twelve,
    Level10One,
    Level10Twelve,
    Level100One,
    Level100Twelve
} 

const numberFormatNull = (number: number | null) => {
    if (!number) return ''

    return numberFormat(number)
}

// 공급가약, 부가세
function getPrice(price: number, month: number) {
    const originPrice = (price * month)
    let vatPrice = 0
    let purePrice = 0
    let discountPrice:number | '' = ''

    if (month === 12) {
        discountPrice = (price * 4)

        const totalPrice = (originPrice - discountPrice) 
        vatPrice = Math.ceil(totalPrice  / 11)
        purePrice = totalPrice - vatPrice
    } else {
        vatPrice = Math.ceil(originPrice / 11)
        purePrice = originPrice - vatPrice
        discountPrice = ''
    }

    return { 
        purePrice,
        vatPrice,
        discountPrice
    }

}

function getValue(val: GetValueVariable): Omit<List, 'id'> {
    if (val === GetValueVariable.Level1000One) {
        const month = 1
        const { purePrice, vatPrice } = getPrice(LevelsPrice[Levels.Level1000], month)
        return {
            name: `${LevelsName[Levels.Level1000]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level1000],
            purePrice,
            vatPrice,
            etc: ''
        }
    }  
    
    if (val === GetValueVariable.Level1000Twelve) {
        const month = 12
        const { purePrice, vatPrice, discountPrice } = getPrice(LevelsPrice[Levels.Level1000], month)
        return {
            name: `${LevelsName[Levels.Level1000]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level1000],
            purePrice,
            vatPrice,
            etc: `${numberFormat(discountPrice)}원 할인`
        }
    } 

    if (val === GetValueVariable.Level10One) {
        const month = 1
        const { purePrice, vatPrice } = getPrice(LevelsPrice[Levels.Level10], month)
        return {
            name: `${LevelsName[Levels.Level10]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level10],
            purePrice,
            vatPrice,
            etc: ''
        }
    }  
    
    if (val === GetValueVariable.Level10Twelve) {
        const month = 12
        const { purePrice, vatPrice, discountPrice } = getPrice(LevelsPrice[Levels.Level10], month)
        return {
            name: `${LevelsName[Levels.Level10]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level10],
            purePrice,
            vatPrice,
            etc: `${numberFormat(discountPrice)}원 할인`
        }
    } 
    
    if (val === GetValueVariable.Level100One) {
        const month = 1
        const { purePrice, vatPrice } = getPrice(LevelsPrice[Levels.Level100], month)
        return {
            name: `${LevelsName[Levels.Level100]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level100],
            purePrice,
            vatPrice,
            etc: ''
        }
    } 
    
    if (val === GetValueVariable.Level100Twelve) {
        const month = 12
        const { purePrice, vatPrice, discountPrice } = getPrice(LevelsPrice[Levels.Level100], month)
        return {
            name: `${LevelsName[Levels.Level100]}(${MonthName[month]})`,
            month,
            unitPrice: LevelsPrice[Levels.Level100],
            purePrice,
            vatPrice,
            etc: `${numberFormat(discountPrice)}원 할인`
        }
    }

    return {
        name: '',
        month: 0,
        unitPrice: 0,
        purePrice: 0,
        vatPrice: 0,
        etc: ''
    }
}

const MyTextField = styled((props: TextFieldProps) => {
    return (
        <TextField variant="standard" {...props}/>
    )
})(() => {
    return {
        textAlign: 'center'
    }  
})

let time: ReturnType<typeof setTimeout>

function PayPerItemComponent(props: Props) {
    const { 
        id, 
        name: cname,
        month: cmonth,
        unitPrice: cunitPrice,
        purePrice: cpurePrice,
        vatPrice: cvatPrice,
        etc: cetc,
        onChange
    } = props

    const [ item, setItem ] = useState<List>({
        id,
        name: cname,
        month: cmonth,
        unitPrice: cunitPrice,
        purePrice: cpurePrice,
        vatPrice: cvatPrice,
        etc: cetc
    })

    const { name, month, unitPrice, purePrice, vatPrice, etc } = item

    function handleChangeItem(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target

        setItem(prevState => ({
            ...prevState,
            [name]: name === 'etc' ? value : String(value).replace(/,/g, '')
        }))
    }

    function handleChangeLevel(val: GetValueVariable) {
        const value = getValue(val)
        setItem({
            ...value,
            id,
        })
    }

    // 직접 가격 적을때만 사용된다
    function HandleChangeOriginPrice(e: React.ChangeEvent<HTMLInputElement>) {
        const value = Number(e.target.value)

        const vatPrice = Math.ceil(value  / 11)
        const purePrice = value - vatPrice

        setItem(prevState => ({
            ...prevState,
            purePrice,
            vatPrice
        }))
    }

    useEffect(() => {

        clearTimeout(time)

        time = setTimeout(() => {
            onChange({ id, name, month, unitPrice, purePrice, vatPrice, etc })
        }, 500)
      
    }, [id, name, month, unitPrice, purePrice, vatPrice, etc , onChange])
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                <ButtonGroup size="small" sx={{marginTop: 1}}>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level1000One)}>베이직(월)</Button>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level1000Twelve)}>베이직(연)</Button>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level10One)}>스탠다드(월)</Button>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level10Twelve)}>스탠다드(연)</Button>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level100One)}>프리미엄(월)</Button>
                    <Button onClick={() => handleChangeLevel(GetValueVariable.Level100Twelve)}>프리미엄(연)</Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="상품명" name="name" value={name} onChange={handleChangeItem}/>
                
            </Grid> 
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="개월" name="month" value={month} onChange={handleChangeItem}/>
            </Grid> 
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="단가" name="unitPrice" value={numberFormatNull(unitPrice)} onChange={handleChangeItem}/>
            </Grid> 
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="원가(pdf에서는 미사용)" name="orignPrice"  onChange={HandleChangeOriginPrice}/>
            </Grid> 
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="공 급 가 액" name="purePrice" value={numberFormatNull(purePrice)} onChange={handleChangeItem}/>
            </Grid> 
            <Grid item xs={6} sm={3} md={3}>
                <MyTextField label="세 액" name="vatPrice" value={numberFormatNull(vatPrice)} onChange={handleChangeItem}/>
            </Grid> 
            <Grid item xs={12} sm={3} md={3}>
                <MyTextField label="비고" multiline name="etc" fullWidth value={etc} onChange={handleChangeItem}/>
            </Grid> 
        </Grid>
    )
}

export default memo(PayPerItemComponent)
export const SHOW = 'backdrop/SHOW'
export const HIDE = 'backdrop/HIDE'

const initState = {
    show: false
}

interface Props {
    type: string
}

function Backdrop(state = initState, action: Props) {
    switch(action.type) {
        case SHOW:
            return {
                show: true,
            }
        case HIDE:
            return {
                show: false
            }
        default:
            return state
    }
}

export default Backdrop


import { ReactChild, ReactChildren, ReactNodeArray, forwardRef, Ref } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import useBreakPoints from 'hooks/useBreakPoints'

interface Props {
    open: boolean
    children: ReactChild | ReactChildren | ReactNodeArray
    onClose: () => void
    fullScreen?: boolean 
    title?: string
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
    Buttons?: React.FunctionComponent<any> 
}


const Transition = forwardRef(
    function Transition(
        props: TransitionProps & {
            children?: React.ReactElement<any, any>
        },
        ref: Ref<unknown>
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    }
)

const TitleBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
})

const DialogComponent = forwardRef((props: Props, ref: Ref<unknown> | undefined) => {
    const { open, title, fullScreen, maxWidth="sm", Buttons, onClose, children } = props

    const { sm } = useBreakPoints()

    const _fullScreen = sm || fullScreen

    return (
        <Dialog open={open} fullScreen={_fullScreen} fullWidth maxWidth={maxWidth} TransitionComponent={Transition} onClose={onClose}>
            <TitleBox>
            {
                title && <DialogTitle sx={{maxWidth: '85%'}}>{title}</DialogTitle>
            }
            {
                _fullScreen && <IconButton onClick={onClose} size="large"><CloseIcon fontSize="large"/></IconButton>
            }
            </TitleBox>
           
            <DialogContent ref={ref}>
                {children}
            </DialogContent>
            {
                Buttons && <DialogActions><Buttons/></DialogActions>
            }
        </Dialog>
    )
})

export default DialogComponent